<template>
    <div class="card">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="filter d-flex align-items-center position-relative fs-6">
                        <div class="filter-item">
                            <span>Toko</span>
                            <el-select v-model="filter.store_id" placeholder="Pilih Toko" clearable @change="onFilter()">
                                <el-option
                                    v-for="(item, i) in listStore"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id"
                                  >
                                    <div class="d-flex">
                                        <div class="col me-3">{{ item.name }}</div>
                                        <div class="col-auto" v-if="item.status == 0">
                                            <small class="text-gray-500"><i>Tidak Aktif</i></small>
                                        </div>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="filter-item">
                            <span>Folder</span>
                            <el-select v-model="filter.folder_id" placeholder="Pilih Folder" clearable @change="onFilter()">
                                <el-option
                                    v-for="(item, i) in listRawFolder"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id"
                                  >
                                    <div class="d-flex">
                                        <div class="col me-3">{{ item.name }}</div>
                                    </div>
                                </el-option>
                            </el-select>
                        </div>
                        <div class="d-flex">
                            <button class="btn btn-icon btn-light-primary me-3" @click="onFilter(), currentPage = 1"><i class="fas fa-search"></i></button>
                            <button class="btn btn-icon btn-light-danger" @click="resetFilter(), currentPage = 1"><i class="fas fa-times"></i></button>
                        </div>
                    </div>
                </div>
                <div class="card-toolbar" style="margin-left:auto">
                </div>
            </div>
        </div>
        <div class="card-body tab-content pt-0">
            <div class="design-analytics row mb-5">
                <div class="px-3 mt-7 sticky">
                    <h3 class="text-uppercase text-white fw-bold m-0 p-5 bg-primary">Desain Retail</h3>
                </div>
                <div
                    v-for="(obj, key) in retail"
                    :key="key"
                    class="design-content px-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                >
                    <div class="row m-0 title text-uppercase d-flex bg-gray-200 px-4 py-3" style="background: #eee !important;">
                        <h4 class="col m-0">{{ obj.title }} (Retail)</h4>
                        <span class="col-auto fw-bolder ms-auto">Total: {{ retail[key].data.map(o => o.total_qty).reduce((p, n) => p + n, 0) }}</span>
                    </div>
                    <div class="list-design" style="background: #ddd !important;" v-if="obj.data.length > 0">
                        <ul class="list-group">
                            <li 
                                v-for="(d, i) in obj.data"
                                :key="i"
                                class="list-group-item d-flex justify-content-between align-items-center py-4"
                            >
                                <div class="d-flex">
                                    <div class="img-content me-5 bg-gray-500 cursor-pointer">
                                        <img class="w-100 h-100" @click="viewImage(d)" :src="img_url_desain+d.gambar" />
                                    </div>
                                    <div class="align-self-center row m-0">
                                        <div class="fs-4 fw-bolder text-uppercase px-0">Desain ID: {{ d.desain_id }}</div>
                                        <small class="text-muted px-0">Tanggal terbit: {{ moment(d.tanggal_terbit).format('DD/MM/YYYY') }}</small>
                                        <span class="badge badge-light w-auto mt-2">
                                            <a class="text-gray-700" :href="'https://intra.apparelsystem.id/bank_desain/view/'+d.desain_id" target="blank">Lihat di bankdesain</a>
                                        </span>
                                    </div>
                                </div>
                                <span class="text-nowrap fw-bolder text-primary badge-pill px-4 py-2 fs-4">{{ d.total_qty }} PCS</span>
                            </li>
                        </ul>
                    </div>
                    <div v-else class="list-design m-0 row justify-content-center text-center py-7">
                        <img class="w-200px" src="/media/illustrations/empty-box.svg" />
                        <h6 class="text-muted text-uppercase mt-1 mb-5">No data found</h6>
                    </div>
                </div>
            </div>
            <div class="design-analytics row mb-5">
                <div class="px-3 mt-7 sticky">
                    <h3 class="text-uppercase text-white fw-bold m-0 p-5 bg-primary">Desain Corporate (Projek)</h3>
                </div>
                <div
                    v-for="(obj, key) in project"
                    :key="key"
                    class="design-content px-3 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-4"
                >
                    <div class="title row m-0 text-uppercase d-flex bg-gray-200 px-4 py-3" style="background: #eee !important;">
                        <h4 class="col m-0">{{ obj.title }} (Projek)</h4>
                        <span class="col-auto fw-bolder ms-auto">Total: {{ project[key].data.map(o => o.total_qty).reduce((p, n) => p + n, 0) }}</span>
                    </div>
                    <div class="list-design" style="background: #ddd !important;" v-if="obj.data.length > 0">
                        <ul class="list-group">
                            <li 
                                v-for="(d, i) in obj.data"
                                :key="i"
                                class="list-group-item d-flex justify-content-between align-items-center py-4"
                            >
                                <div class="d-flex">
                                    <div class="img-content me-5 bg-gray-500 cursor-pointer">
                                        <img class="w-100 h-100" @click="viewImage(d)" :src="img_url_desain+d.gambar" />
                                    </div>
                                    <div class="align-self-center row m-0">
                                        <div class="fs-4 fw-bolder text-uppercase px-0">Desain ID: {{ d.desain_id }}</div>
                                        <small class="text-muted px-0">Tanggal terbit: {{ moment(d.tanggal_terbit).format('DD/MM/YYYY') }}</small>
                                        <span class="badge badge-light w-auto mt-2">
                                            <a class="text-gray-700" :href="'https://intra.apparelsystem.id/bank_desain/view/'+d.desain_id" target="blank">Lihat di bankdesain</a>
                                        </span>
                                    </div>
                                </div>
                                <span class="text-nowrap fw-bolder text-primary badge-pill px-4 py-2 fs-4">{{ d.total_qty }} PCS</span>
                            </li>
                        </ul>
                    </div>
                    <div v-else class="list-design m-0 row justify-content-center text-center py-7">
                        <img class="w-200px" src="/media/illustrations/empty-box.svg" />
                        <h6 class="text-muted text-uppercase mt-1 mb-5">No data found</h6>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog v-model="getZoomImage.visibility" :title="getZoomImage.title" width="50%" lock-scroll top="5vh">
            <inner-image-zoom :src="getZoomImage.src"/>
        </el-dialog>
    </div>
</template>

<style type="text/css" scoped>
    .design-analytics .design-content {
        border-right:  solid 1px #eee;
        padding: 2rem 1rem;
        border-radius: 4px;
        border-left: none;
        border-right: none;
    }
    .design-analytics .design-content:first-child {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .design-analytics .design-content:not(:first-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .design-analytics .list-design .list-group-item {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: solid 1px #eee !important;

        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .design-analytics .list-design .list-group-item:last-child {
        border-bottom: none !important;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }

    .list-design {
        border: solid 1px #eee;
    }

    .list-design .img-content {
        max-width: 80px; 
        max-height: 80px;
        min-width: 80px;
        min-height: 80px;
    }
</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";

export default defineComponent({
    components: { 
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        'inner-image-zoom': InnerImageZoom,
    },
    setup() {
        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore()

        const loadingSubmit = ref(false)
        const loadingTable = ref(false);
        const tableRef = ref(null)

        const retail = reactive([])
        const project = reactive([])

        const filterActive = ref(false)
        const filter = reactive({
            store_id: null,
            folder_id: null,
            date: null,
        })

        // FILTER
        const dateShortcuts = [
          {
            text: 'Last week',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              return [start, end]
            },
          },
          {
            text: 'Last month',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              return [start, end]
            },
          },
          {
            text: 'Last 3 months',
            value: () => {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              return [start, end]
            },
          },
        ]

        const listStore = computed(() => store.getters.getListStore)
        const listRawFolder = computed(() => store.getters.getListRawFolder)

        const onFilter = async (type) => {
            // if(Object.values(filter).every(o => o == null || o.length == 0)) {
            //     return
            // }

            store.dispatch(Actions.ADD_FILTER, filter);
            filterActive.value = false

            await getData()

            Object.values(filter).every(o => o == null || o.length == 0)
                ? filterActive.value = false
                : filterActive.value = true
        }

        const resetFilter = async (type) => {
            filter.store_id = null
            filter.folder_id = null
            filter.date = null
            
            loadingTable.value = true

            filterActive.value = false

            await getData()

            loadingTable.value = false
        }

        const getData = async () => {
            const loading= ElLoading.service({
                lock: true,
                text: 'Loading..',
                background: 'rgba(255,255,255,0.5)'
            })

            try {
                await retail.splice(0)
                await project.splice(0)
                
                let store = filter.store_id ? '&store=' + filter.store_id : '';
                let folder = filter.folder_id ? '&folder=' + filter.folder_id : '';

                await ApiService.get("report_desain?" + store + folder)
                .then((response) => {
                    Object.assign(retail, response.data.data.retail)
                    Object.assign(project, response.data.data.project)
                })
                .catch((error) => {
                    console.log('Error getting design report');
                });

                project.sort((a, b) => b.data.length - a.data.length);
                retail.sort((a, b) => b.data.length - a.data.length);

            } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loading.close()
        }

        const getZoomImage = reactive({
            title: '',
            src: '',
            visibility: false,
        })

        const viewImage = (data) => {
            getZoomImage.visibility = true
            getZoomImage.title = 'DESAIN ID: '+data.desain_id
            getZoomImage.src = img_url_desain.value + data.gambar
        }

        onMounted(async() => {
            setCurrentPageBreadcrumbs("Laporan Desain", ['Laporan Desain']);
            
            store.dispatch(Actions.LIST_STORE);
            store.dispatch(Actions.LIST_RAW_FOLDER);

            getData()
        })

        return {
            formatIDR, moment, dateShortcuts,
            img_url_mockup, img_url_desain,
            viewImage, getZoomImage,
            onFilter, resetFilter, filter, listStore, listRawFolder,
            retail, project,
        }
    }
})
</script>